<template>
    <div
        class="event-participant-card-view"
    >
        <event-participant-card-full
            :event-id="eventId"
            :participant-id="participantId"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import EventParticipantCardFull from '@/modules/event/components/participant/EventParticipantCardFull';

export default {
    name: 'EventParticipantCardView',

    components: {
        EventParticipantCardFull
    },

    mixins: [
        breadcrumbs
    ],

    computed: {

        ...mapState('event', {
            event: 'resource'
        }),

        ...mapState('event/participants', [
            'participantCard'
        ]),

        title() {
            return this.$prop('event.attributes.title');
        },

        participantName() {
            return this.$prop('participantCard.relationships.participant.attributes.fullname', '');
        },

        $breadcrumbs() {
            const bc = [
                ['События', ROUTES.EVENT.LIST]
            ];
            if (this.title) {
                bc.push([this.title, ROUTES.EVENT.CARD.PARTICIPANTS, { eventId: this.eventId }]);
            }
            if (this.participantName) {
                bc.push([
                    `Участник: ${this.participantName}`,
                    ROUTES.EVENT.PARTICIPANT.CARD,
                    {
                        eventId: this.eventId,
                        participantId: this.participantId
                    }
                ]);
            }
            return bc;
        },

        eventId() {
            return this.$route.params.eventId;
        },

        participantId() {
            return this.$route.params.participantId;
        }
    },

    watch: {
        eventId() {
            this._loadEvent();
        }
    },

    mounted() {
        if (!this.event) {
            this._loadEvent();
        }
    },

    methods: {
        ...mapActions('event', [
            'getResource'
        ]),

        _loadEvent() {
            this.getResource({
                eventId: this.eventId
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
