<template>
    <div class="participant-discount-info">
        <el-switch
            v-model="childrenProgram"
            @change="_onChange"
        />
    </div>
</template>

<script>
import { Event } from '@/api';
import { mapActions } from 'vuex';

export default {
    name: 'ParticipantChildrenProgramSwitch',


    props: {
        eventId: {
            type: String,
            default: null
        },

        participantId: {
            type: String,
            default: null
        },

        currentValue: {
            type: Boolean,
            default: undefined
        }
    },

    data() {
        return {
            childrenProgram: false
        };
    },

    mounted() {
        this.childrenProgram = this.currentValue;
    },

    methods: {
        ...mapActions('event/participants', [
            'getTransactions'
        ]),

        _onChange: _.debounce(function() {
            this._updateChildrenProgramStatus();
        }, 300),

        _updateChildrenProgramStatus() {
            Event.Participant.updateChildrenProgram({
                participantId: this.participantId,
                eventId: this.eventId,
                childrenProgram: this.childrenProgram
            })
                .then(response => {
                    this.$emit('changed', {
                        childrenProgram: this.childrenProgram
                    });
                    this.$message.success('Статус участия в детской программе обновлён');
                    this.getTransactions({
                        eventId: this.eventId,
                        participantId: this.participantId
                    });
                })
                .catch(e => {
                    const message = _.get(e, 'message', 'Не удалось обновить статус участия в детской программе');
                    this.$message.error(message);
                });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";
</style>
