<template>
    <div class="actions-history">
        <div
            v-if="showTable"
            class="actions-history__wrapper"
        >
            <el-row
                v-for="item in historyData"
                :key="item.id"
                class="actions-history__row"
            >
                <!-- eslint-disable -->
                <div
                    class="actions-history__text"
                    v-html="item.attributes.text"
                />
                <!-- eslint-enable -->
                <div class="actions-history__date">
                    {{ item.attributes.created_at | moment("D MMMM YYYY HH:mm") }}
                </div>
            </el-row>
        </div>
        <div
            v-else
            class="actions-history__no-data"
        >
            Нет данных
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'ActionsHistory',
    props: {
        historyData: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        showTable() {
            return this.historyData.length > 0;
        }
    }
};
</script>

<style lang="scss">
@import "@vars";
.actions-history {
    &__row {
        border-bottom: 1px solid #EBEEF5;
        font-family: "PT Sans", sans-serif;
    }
    &__text {
        font-size: 14px;
        color: #000000;
    }
    &__date {
        font-size: 12px;
        color: #979797;
        margin-bottom: 5px;
    }
    &__no-data {
        line-height: 60px;
        color: #909399;
        text-align: center;
        font-size: 14px;
    }
}
</style>
