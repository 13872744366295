<template>
    <div class="events-history">
        <el-table
            class="events-history__table"
            :data="eventHistoryData"
            :show-header="false"
        >
            <el-table-column
                label="Событие"
                min-width="180"
            >
                <template slot-scope="scope">
                    <router-link :to="_eventRoute(scope.row)">
                        {{ scope.row.eventTitle }}
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="Статус"
                min-width="80"
            >
                s
                <template slot-scope="scope">
                    <el-select
                        v-model="scope.row.statusId"
                        class="events-history__table-status-select"
                        size="mini"
                        @change="onStatusChange(scope.row)"
                    >
                        <el-option
                            v-for="status in statuses"
                            :key="status.code"
                            :label="status.title"
                            :value="status.code"
                        />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="Удалить"
                width="45"
            >
                <template slot-scope="scope">
                    <i
                        class="el-icon-delete events-history__remove-event"
                        @click="onRemoveParticipantRowClick(scope.row)"
                    />
                </template>
            </el-table-column>
        </el-table>
        <el-button
            type="text"
            @click="_onEventHistoryAddBtnClick"
        >
            <i class="fas fa-plus" />
            Добавить
        </el-button>
        <el-dialog
            :visible="confirmDeleteDialogVisible"
            custom-class="events-history__dialog"
            width="400px"
            :show-close="false"
            :close-on-click-modal="false"
        >
            <div class="events-history__dialog-title">
                Удалить фестиваль?
            </div>
            <div class="events-history__dialog-buttons">
                <el-button
                    class="events-history__dialog-confirm"
                    type="primary"
                    size="small"
                    :disabled="isDeleteDialogConfirmBtnDisabled"
                    @click="onDeleteDialogConfirm"
                >
                    Да {{ countdownText }}
                </el-button>
                <el-button
                    class="events-history__dialog-cancel"
                    type="danger"
                    size="small"
                    @click="onDeleteDialogCancel"
                >
                    Нет
                </el-button>
            </div>
        </el-dialog>
        <event-history-add-participant-dialog
            :is-visible="isEventHistoryAddDialogVisible"
            @close-dialog="_onEventHistoryAddParticipantDialogCloseBtnClick"
            @updated="_onEventHistoryAddParticipantUpdated"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import { ROUTES } from '@/enums';
import EventHistoryAddParticipantDialog from '@/modules/event/components/participant/EventHistoryAddParticipantDialog';
import { Participant } from '@/api';

export default {
    name: 'EventHistory',
    components: { EventHistoryAddParticipantDialog },
    data() {
        return {
            confirmDeleteDialogVisible: false,
            participantToDelete: null,
            deleteDialogConfirmCountdown: null,
            deleteBtnInterval: null,
            deleteBtnTimeout: null,
            isEventHistoryAddDialogVisible: false
        };
    },
    computed: {
        ...mapState('event/participants', [
            'participantCard'
        ]),

        ...mapState('participant', [
            'statusList',
            'eventHistoryList'
        ]),

        isDeleteDialogConfirmBtnDisabled() {
            return this.deleteDialogConfirmCountdown > 0;
        },

        countdownText() {
            return this.isDeleteDialogConfirmBtnDisabled ? `(${this.deleteDialogConfirmCountdown})` : '';
        },

        eventHistory() {
            return this.$prop('eventHistoryList', []);
        },

        participantId() {
            return this.$route.params.participantId;
        },

        eventHistoryData() {
            return this.eventHistory.map(value => {
                return {
                    participantId: _.get(value, 'id'),
                    eventId: _.get(value, 'attributes.eventId', ''),
                    eventTitle: _.get(value, 'attributes.eventTitle', ''),
                    statusId: _.get(value, 'attributes.statusId', ''),
                    statusTitle: _.get(value, 'attributes.statusTitle', '')
                };
            });
        },

        statuses() {
            return this.statusList.map(status => {
                return {
                    code: _.get(status, 'id'),
                    title: _.get(status, 'attributes.title')
                };
            });
        }
    },
    watch: {
        participantId: {
            handler: '_loadData',
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.getStatusList();
    },
    beforeDestroy() {
        this.setEventHistoryList([]);
    },
    methods: {
        ...mapActions('event/participants', [
            'updateStatus'
        ]),
        ...mapActions('participant', [
            'getStatusList',
            'getEventHistoryList',
            'deleteParticipant'
        ]),
        ...mapMutations('participant', [
            'setEventHistoryList'
        ]),
        _loadData() {
            this.getEventHistoryList(this.participantId)
                .catch(() => {});
        },
        fireUpdatedEvent() {
            this.$emit('updated');
        },
        _eventRoute(row) {
            return {
                name: ROUTES.EVENT.PARTICIPANT.CARD,
                params: {
                    eventId: row.eventId,
                    participantId: row.participantId
                }
            };
        },
        _tryRemoveParticipant() {
            let data = this.participantToDelete;
            if (!data) {
                return;
            }
            this._removeParticipant(data.participantId);
        },
        _removeParticipant(partId) {
            this.deleteParticipant(partId)
                .then(() => {
                    this._closeDialog();
                    this.fireUpdatedEvent();
                    this._loadData();
                });
        },
        _showDeleteDialog(row) {
            this.confirmDeleteDialogVisible = true;
            this.participantToDelete = row;
            this.deleteDialogConfirmCountdown = 10;
            this.deleteBtnInterval = setInterval(() => {
                this.deleteDialogConfirmCountdown--;
                if (this.deleteDialogConfirmCountdown === 0) {
                    clearInterval(this.deleteBtnInterval);
                }
            }, 1000);
        },
        _closeDialog() {
            this.confirmDeleteDialogVisible = false;
            this.participantToDelete = null;
            clearInterval(this.deleteBtnInterval);
            clearTimeout(this.deleteBtnTimeout);
        },
        _onEventHistoryAddBtnClick() {
            this.isEventHistoryAddDialogVisible = true;
        },
        _onEventHistoryAddParticipantDialogCloseBtnClick() {
            this.isEventHistoryAddDialogVisible = false;
        },
        _onEventHistoryAddParticipantUpdated() {
            this.isEventHistoryAddDialogVisible = false;
            this._loadData();
            this.fireUpdatedEvent();
        },
        onRemoveParticipantRowClick(row) {
            this._showDeleteDialog(row);
        },
        onDeleteDialogConfirm() {
            this._tryRemoveParticipant();
            this._closeDialog();
        },
        onDeleteDialogCancel() {
            this._closeDialog();
        },
        onStatusChange({ participantId, statusId }) {
            let statusData = {
                status: statusId
            };
            Participant.updateParticipantField(
                participantId,
                statusData
            )
                .then(() => {
                    this.fireUpdatedEvent();
                    this._loadData();
                })
                .catch(e => {
                    const message = _.get(e, 'message', 'Не удалось обновить стаутс гостя');
                    this.$message.error(message);
                });
        }
    }
};
</script>

<style lang="scss">
.events-history {
  &__table {
    margin-bottom: 15px;
    width: 100%;
  }

  &__remove-event {
    cursor: pointer;
    color: #eb2929;
    font-size: 1.2em;
  }

  &__dialog {
    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 50px 0 65px;
    }
  }

  &__dialog-title {
    color: #000;
    font-size: 36px;
    margin-bottom: 35px;
    text-align: center;
  }

  &__dialog-buttons {
    text-align: center;
  }

  &__dialog-confirm.el-button {
    padding: 12px 40px;
  }

  &__dialog-cancel.el-button {
    background-color: #eb2929;
    margin-left: 25px;
    padding: 12px 40px;
  }

  &__table-status-select {
    .el-input__inner {
      border: 1px solid transparent;
    }
  }
}
</style>
