<template>
    <div class="participant-name-with-age">
        <router-link
            v-if="withLink"
            :to="cardRoute"
            class="participant-name-with-age__name participant-name-with-age__link"
        >
            {{ name }}
        </router-link>
        <div
            v-else
            class="participant-name-with-age__name"
        >
            {{ name }}
        </div>
        <div class="participant-name-with-age__age">
            <age-text :years="age" />
        </div>
    </div>
</template>

<script>
import { ROUTES } from '@/enums';
import AgeText from '@/components/common/AgeText';

export default {
    name: 'ParticipantNameWithAge',

    components: {
        AgeText
    },

    props: {
        withLink: {
            type: Boolean,
            default: true
        },

        id: {
            type: String,
            default: null
        },

        eventId: {
            type: String,
            default: null
        },

        name: {
            type: String,
            default: null
        },

        age: {
            type: [Number, String],
            default: 0
        }
    },

    computed: {
        cardRoute() {
            return {
                name: ROUTES.EVENT.PARTICIPANT.CARD,
                params: {
                    eventId: this.eventId,
                    participantId: this.id
                }
            };
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.participant-name-with-age {
    &__name {
        display: inline-block;
    }

    &__link {
        cursor: pointer;
        text-decoration: none;
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }

    &__age {
        display: inline-block;
        color: rgba(0, 0, 0, .5);
        font-size: .8em;
        position: relative;
        top: -5px;
        margin-left: 6px;
    }
}
</style>
