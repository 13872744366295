<template>
    <el-button
        type="primary"
        size="mini"
        @click="_onCheckInClick"
    >
        {{ buttonText }}
    </el-button>
</template>

<script>
import { mapMutations } from 'vuex';
import { ROUTES } from '@/enums';

export default {
    name: 'CheckInBtn',
    props: {
        selectedParticipant: {
            type: Object,
            default: () => {}
        },
        reCheckIn: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonText() {
            return this.reCheckIn ? 'Переселить' : 'Заселить';
        }
    },
    methods: {

        ...mapMutations('event/participants', [
            'setSelectedParticipants',
            'setRecheckInMode'
        ]),

        _onCheckInClick() {
            this.setSelectedParticipants({
                selectedParticipants: [this.selectedParticipant]
            });
            this.setRecheckInMode({ mode: this.reCheckIn });
            this.$router.push({
                name: ROUTES.EVENT.CARD.HOTEL
            });
        }
    }
};
</script>

<style scoped>

</style>
