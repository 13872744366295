<template>
    <div>
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
        >
            <el-form-item
                label="Сумма"
                :label-width="formLabelWidth"
                prop="amount"
            >
                <el-input-number
                    v-model="form.amount"
                    :step="1000"
                />
            </el-form-item>
            <!--<el-form-item label="Тип" :label-width="formLabelWidth" prop="type">-->
            <!--<el-select v-model="form.type" placeholder="Выберите тип">-->
            <!--<el-option label="Приход" value="income"></el-option>-->
            <!--<el-option label="Расход" value="expense"></el-option>-->
            <!--</el-select>-->
            <!--</el-form-item>-->
            <el-form-item
                label="Категория"
                :label-width="formLabelWidth"
                prop="category"
            >
                <el-select
                    v-model="form.category"
                    placeholder="Выберите категорию"
                >
                    <el-option
                        label="Предоплата"
                        value="prepay"
                    />
                    <el-option
                        label="Заселение"
                        value="checkin"
                    />
                    <el-option
                        label="Консультация"
                        value="consultation"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                label="Комментарий"
                :label-width="formLabelWidth"
                prop="comment"
            >
                <el-input
                    v-model="form.comment"
                    autocomplete="off"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                />
            </el-form-item>
        </el-form>
        <span
            slot="footer"
            class="dialog-footer"
        >
            <el-button @click="_onCancel()">Отмена</el-button>
            <el-button
                type="primary"
                @click="_onSubmit()"
            >Добавить</el-button>
        </span>
    </div>
</template>

<script>
import TRANSACTIONS from '@/modules/event/enums/transactions';
import Event from '@/modules/event/api';

export default {
    name: 'PaymentsAdd',
    data() {
        let checkAmount = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('Введите сумму'));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error('Должны быть цифры'));
                } else {
                    if (value === 0) {
                        callback(new Error('Должно быть отлично от 0'));
                    } else {
                        callback();
                    }
                }
            }, 1000);
        };

        return {
            form: {
                amount: 0,
                category: [
                    TRANSACTIONS.CATEGORIES.PREPAY,
                    TRANSACTIONS.CATEGORIES.CHECKIN,
                    TRANSACTIONS.CATEGORIES.CONSULTATION
                ],
                comment: ''
            },
            formLabelWidth: '120px',

            rules: {
                amount: [
                    { validator: checkAmount, trigger: 'blur' }
                ],
                type: [
                    { required: true, message: 'Укажите: Приход или Расход', trigger: 'change' }
                ],
                category: [
                    { required: true, message: 'Укажите категорию', trigger: 'change' }
                ],
                comment: [
                    { required: true, message: 'Укажите комментарий', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        _resetForm() {
            this.$refs['form'].resetFields();
        },
        _onCancel() {
            this.$emit('close');
            this._resetForm();
        },

        async _addTransaction() {
            /** @type {ApiResponse} */
            let response = await Event.Transactions.store({
                transactionData: { ...this.form},
                eventId: this.$route.params.eventId,
                participantId: this.$route.params.participantId
            });
            if (response.success) {
                this.$message.success('Запись добавлена!');
                this.$emit('close');
                this._resetForm();
            } else {
                this.$message.error('Ошибка! ' + JSON.stringify(response.data));
            }
        },

        async _onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this._addTransaction();
                }
            });
        }
    }
}
;
</script>

<style scoped>

</style>