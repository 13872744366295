<template>
    <div class="payments-history">
        <el-table
            v-if="!!transactionList"
            :data="transactionList"
            :show-header="false"
        >
            <el-table-column
                prop="attributes.comment"
                label="Комментарий"
                align="left"
                class-name="payments-history-table__cell"
                width="220"
            />
            <el-table-column
                prop="attributes.created_at"
                label="Создано"
                align="left"
                width="130"
            />
            <el-table-column
                prop="attributes.amount"
                label="Сумма"
                align="right"
            />
        </el-table>
        <div class="payments-history__addButton">
            <el-button
                type="text"
                @click="_onAddTransClick()"
            >
                <i class="fas fa-plus" />
                Добавить оплату
            </el-button>
        </div>
        <div class="payments-history__balance">
            Итого: {{ balance }}
        </div>
        <el-dialog
            title="Добавить оплату"
            :visible.sync="dialogFormVisible"
        >
            <payments-add
                @close="_onDialogClose()"
            />
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PaymentsAdd from '@/modules/event/components/participant/PaymentsAdd';

export default {
    name: 'PaymentsHistory',
    components: { PaymentsAdd },
    data() {
        return {
            dialogFormVisible: false
        };
    },
    computed: {
        ...mapState('event/participants', [
            'transactions'
        ]),
        transactionList() {
            return this.$prop('transactions.data');
        },
        balance() {
            return this.$prop('transactions.meta.balance');
        },
        participantId() {
            return this.$route.params.participantId;
        }
    },
    mounted() {
        if (this.participantId) {
            this.$echo.private(`Participant.${this.participantId}`)
                .listen('TransactionUpdated', () => {
                    this._getTransactionList();
                });
        }
    },
    destroyed() {
        this.$echo.leave(`Participant.${this.participantId}`);
    },
    methods: {
        ...mapActions('event/participants', [
            'getTransactions'
        ]),

        _showTransactionDialog() {
            this.dialogFormVisible = true;
        },

        _onAddTransClick() {
            this._showTransactionDialog();
        },

        _onDialogClose() {
            this.dialogFormVisible = false;
            this._getTransactionList();
        },

        _getTransactionList() {
            this.getTransactions({
                eventId: this.$route.params.eventId,
                participantId: this.$route.params.participantId
            });
        }

    }
};
</script>

<style lang="scss">

.payments-history-table__cell div.cell {
    word-break: normal;
}

.payments-history {

    &__balance {
        text-align: right;
        font-size: 24px;
        font-weight: 500;
        margin: 30px 0;
        display: inline-block;
        float: right;

    }
    &__addButton {
        display: inline-block;
        margin: 25px 0;
        float: left;
        font-weight: 100;
    }
}
</style>
