import { render, staticRenderFns } from "./ActionsHistory.vue?vue&type=template&id=2ec78fbf&"
import script from "./ActionsHistory.vue?vue&type=script&lang=js&"
export * from "./ActionsHistory.vue?vue&type=script&lang=js&"
import style0 from "./ActionsHistory.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports