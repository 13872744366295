<template>
    <span class="place-inline">
        <el-tooltip
            :content="tooltip"
            placement="left"
            transition="none"
            :open-delay="200"
        >
            <span slot="default">
                <i class="fas fa-hotel" />
                {{ buildingTitle }}
                № {{ roomTitle }}
            </span>
        </el-tooltip>
    </span>
</template>

<script>
export default {
    name: 'PlaceInline',

    props: {
        /** @type {PlaceResource} */
        place: {
            type: Object,
            default: null
        }
    },

    computed: {
        tooltip() {
            let parts = [];
            if (this.hotelTitle) {
                parts.push(`${this.hotelTitle}`);
            }
            if (this.buildingTitle) {
                parts.push(`Корпус "${this.buildingTitle}"`);
            }
            if (this.roomTitle) {
                parts.push(`Номер "${this.roomTitle}"`);
            }
            return parts.join(' / ');
        },

        /** @returns {HotelResource | null} */
        hotel() {
            return this.$prop('place.relationships.hotel');
        },

        /** @returns {RoomResource | null} */
        room() {
            return this.$prop('place.relationships.room');
        },

        /** @returns {BuildingResource | null} */
        building() {
            return this.$prop('place.relationships.building');
        },

        hotelTitle() {
            return this.$prop('hotel.attributes.title');
        },

        buildingTitle() {
            return this.$prop('building.attributes.title');
        },

        roomTitle() {
            return this.$prop('room.attributes.title');
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.place-inline {
    display: inline-block;
}
</style>
