<template>
    <div v-loading="isLoading">
        <h1 class="app-h1">
            {{ name }}
        </h1>

        <el-row :gutter="16">
            <el-col :span="12">
                <el-card shadow="none">
                    <general-info
                        @update-info="_load"
                    />
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card
                    v-if="hasSmallChildren"
                    shadow="none"
                >
                    <div slot="header">
                        <h2 class="app-h2">
                            Дети до 3-х лет
                        </h2>
                    </div>
                    <div>
                        <div
                            v-for="child in smallChildren"
                            :key="child.id"
                        >
                            <table class="table">
                                <tr>
                                    <td
                                        width="160px"
                                        class="table__td table__td--gray"
                                    >
                                        Фамилия, имя, отчество
                                    </td>
                                    <td
                                        colspan="2"
                                        class="table__td"
                                    >
                                        {{ child.name }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="table__td table__td--gray">
                                        Дата рождения
                                    </td>
                                    <td class="table__td">
                                        {{ child.dateOfBirth | moment('DD MMMM YYYY') }}
                                    </td>
                                    <td
                                        width="100px"
                                        class="table__td"
                                        style="color: #239f18"
                                    >
                                        <age-text :years="child.age" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </el-card>
                <el-card shadow="none">
                    <div slot="header">
                        <h2 class="app-h2">
                            История оплат
                        </h2>
                    </div>
                    <payments-history />
                </el-card>
                <el-card shadow="none">
                    <div slot="header">
                        <h2 class="app-h2">
                            История фестивалей
                        </h2>
                    </div>
                    <event-history
                        @updated="onEventHistoryUpdate"
                    />
                </el-card>
                <el-card shadow="none">
                    <el-collapse v-model="logCollapse">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <h2 class="app-h2">
                                    История операций
                                </h2>
                            </template>
                            <div v-loading="loading">
                                <actions-history
                                    v-if="data"
                                    :history-data="data"
                                />
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PaymentsHistory from '@/modules/event/components/participant/PaymentsHistory';
import EventHistory from '@/modules/event/components/participant/EventHistory';
import ActionsHistory from '@/modules/event/components/participant/ActionsHistory';
import GeneralInfo from '@/modules/event/components/participant/GeneralInfo';
import AgeText from '@/components/common/AgeText';
import { ROUTES } from '@/enums';

export default {
    name: 'EventParticipantCardFull',

    components: {
        AgeText,
        GeneralInfo,
        ActionsHistory,
        EventHistory,
        PaymentsHistory
    },

    props: {
        participantId: {
            type: String,
            default: null
        },

        eventId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            activeCollapsibleItem: 'payments',
            logCollapse: ['1']
        };
    },

    computed: {
        ...mapState('event/participants', [
            'participantCard',
            'isLoading'
        ]),
        ...mapGetters('eventNew/participant', [
            'loading',
            'data'
        ]),

        isReady() {
            return !!this.participantCard;
        },

        name() {
            return this.$prop('participantCard.relationships.participant.attributes.fullname', '');
        },

        group() {
            return this.$prop('participantCard.relationships.group', []);
        },

        hasSmallChildren() {
            return this.smallChildren && this.smallChildren.length;
        },

        /** @type {ParticipantResource[] | null} */
        possiblePreviousParticipants() {
            return this.$prop('participantCard.relationships.possiblePreviousParticipants');
        },

        /**
         * @return {ParticipantResource[]}
         */
        smallChildren() {
            /**
             * @param {ParticipantResource} x
             * @return {Boolean}
             */
            const isChild = x => {
                return x.attributes.is_small_child;
            };
            /**
             * @param {ParticipantResource} x
             * @return {{name, age, dateOfBirth}}
             */
            const mapper = x => {
                return {
                    name: x.attributes.fullname,
                    age: x.attributes.age,
                    dateOfBirth: x.attributes.date_of_birth
                };
            };
            return this.group
                .filter(isChild)
                .map(mapper);
        }
    },

    watch: {
        participantId() {
            this._load();
        }
    },

    mounted() {
        this._load();
    },

    destroyed() {
        this.setParticipantCard({ participantCard: null });
    },

    methods: {
        ...mapMutations('event/participants', [
            'setParticipantCard'
        ]),

        ...mapActions('event/participants', [
            'getParticipantFullCard',
            'getTransactions'
        ]),
        ...mapActions('eventNew/participant', [
            'fetchData'
        ]),

        onEventHistoryUpdate() {
            this._load();
        },

        _load() {
            this.getParticipantFullCard({
                eventId: this.eventId,
                participantId: this.participantId
            })
                .catch(() => {
                    this.$router.push({ name: ROUTES.EVENT.CARD.PARTICIPANTS });
                });
            this.getTransactions({
                eventId: this.$route.params.eventId,
                participantId: this.$route.params.participantId
            });
            this.fetchData({ group: 'participant', id: this.participantId });
        },

        _onCollapse() {
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.event-participant-card-view {
  max-width: 1000px;
}

.el-card {
  margin-bottom: 20px;
}

.el-collapse,
.el-collapse-item__wrap {
  border-top: none;
  border-bottom: none;
}


</style>
