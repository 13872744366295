<template>
    <div class="participant-short-info">
        <el-row :gutter="16">
            <el-col :span="12">
                <div class="participant-short-info__name">
                    <participant-name-with-age
                        :id="id"
                        :with-link="withLink"
                        :event-id="eventId"
                        :name="name"
                        :age="age"
                    />
                </div>
            </el-col>
            <el-col :span="4">
                {{ status }}
            </el-col>
            <el-col :span="6">
                {{ ageStatus }}
            </el-col>
            <el-col :span="2">
                <gender-icon :gender="gender" />
            </el-col>
        </el-row>
        <el-row
            v-if="withEventInfo"
            :gutter="16"
        >
            <el-col :span="24">
                ИД события: {{ eventId }}
            </el-col>
        </el-row>
    </div>
</template>

<script>
import GenderIcon from '@/components/common/GenderIcon';
import ParticipantNameWithAge from '@/modules/participant/components/common/ParticipantNameWithAge';
import { PARTICIPANTS } from '@/enums';

export default {
    name: 'ParticipantShortInfo',
    components: { ParticipantNameWithAge, GenderIcon },
    props: {
        /** @type {ParticipantResource} */
        participant: {
            type: Object,
            default: null
        },

        withLink: {
            type: Boolean,
            default: true
        },

        /**
         * todo
         */
        withEventInfo: {
            type: Boolean,
            default: false
        }
    },

    computed: {

        id() {
            return this.$prop('participant.id');
        },

        eventId() {
            return this.$prop('participant.attributes.event_id');
        },

        name() {
            return this.$prop('participant.attributes.fullname');
        },

        status() {
            return this.$prop('participant.attributes.status');
        },

        gender() {
            return this.$prop('participant.attributes.gender');
        },

        dateOfBirth() {
            return this.$prop('participant.attributes.date_of_birth');
        },

        age() {
            return this.$prop('participant.attributes.age');
        },

        isChild() {
            return this.$prop('participant.attributes.is_child', false);
        },

        isSmallChild() {
            return this.$prop('participant.attributes.is_small_child', false);
        },

        ageStatus() {
            return this.isSmallChild ? 'М. ребенок' : this.isChild ? 'Ребенок' : 'Взрослый';
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
