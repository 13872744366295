<template>
    <div
        v-loading="isLoading"
        class="event-list"
    >
        <el-row>
            <el-col>
                <el-input
                    v-model="searchField"
                    class="event-list__search-field"
                    clearable
                    prefix-icon="el-icon-search"
                    @input="_onSearchChange"
                />
            </el-col>
        </el-row>
        <el-table
            :data="preparedData"
            style="width: 100%"
        >
            <el-table-column
                prop="name"
                label="Имя"
                :width="200"
            />

            <el-table-column
                prop="phone"
                label="Телефон"
                :width="120"
            />
            <el-table-column
                prop="phone2"
                label="Whatsapp"
                :width="120"
            />

            <el-table-column
                prop="dateOfBirth"
                label="Дата рождения"
                :width="120"
            />
            <el-table-column
                prop="city"
                label="Город"
            />
            <el-table-column
                prop="email"
                label="Email"
            />
            <el-table-column
                prop="onlineCaptain"
                label="Капитан"
            />
            <el-table-column
                prop="status"
                label="Статус"
            />
            <el-table-column
                prop="createdAt"
                label="Дата регистрации"
            />
            <el-table-column
                prop="successPaymentDate"
                label="Дата оплаты"
            />
        </el-table>
        <el-row
            type="flex"
            justify="center"
        >
            <el-pagination
                layout="total, prev, pager, next, sizes"
                :current-page.sync="meta.current_page"
                :page-count="meta.last_page"
                :page-size="Number.parseInt(meta.per_page)"
                :total="meta.total"
                :page-sizes="[15, 30, 40, 50]"
                @size-change="_onPageSizeChange"
                @current-change="_onPageChange"
            />
        </el-row>
    </div>
</template>

<script>
import { OnlineEvent } from '@/api';
import _ from 'lodash';

export default {
    name: 'OnlineEventParticipantList',

    data() {
        return {
            searchField: '',
            participants: [],
            meta: {
                current_page: 1,
                last_page: 1,
                per_page: 15,
                total: 20
            },
            isLoading: false
        };
    },

    computed: {
        preparedData() {
            const mapper = x => ({
                id: x.id,
                name: `${_.get(x, 'attributes.lastname', '')} ${_.get(x, 'attributes.firstname', '')}`,
                phone: x.attributes.phone,
                phone2: x.attributes.phone2,
                dateOfBirth: x.attributes.date_of_birth,
                city: x.attributes.city,
                email: x.attributes.email,
                status: x.attributes.status === 'PAID' ? 'Оплатил' : 'Новый',
                onlineCaptain: _.get(x, 'relationships.online_captain.attributes.fullname', ''),
                createdAt: _.get(x, 'attributes.created_at'),
                successPaymentDate: _.get(x, 'relationships.success_payment.attributes.updated_at')
            });
            return this.participants.map(mapper);
        }
    },

    mounted() {
        this._loadData();
        this.searchField = this.$route.query.search || '';
    },

    methods: {
        _loadData() {
            this.isLoading = true;
            OnlineEvent.Participant.getList({ onlineEventId: this.$route.params.onlineEventId, query: this.$route.query })
                .then(response => {
                    this.participants = response.data;
                    this.meta = response.meta;
                })
                .catch(e => {
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        _onPageSizeChange(pageSize) {
            this.$router.push({ query: { ...this.$route.query, limit: pageSize, page: '1' } });
            this._loadData();
        },

        _onPageChange(page) {
            this.$router.push({ query: { ...this.$route.query, page: page } });
            this._loadData();
        },

        _onSearchChange: _.debounce(function(search) {
            this.$router.push({ query: { ...this.$route.query, search } });
            this._loadData();
        }, 300)
    }
};
</script>

<style lang="scss">
@import "@vars";

.event-list {
  max-width: 1000px;
  &__search-field {
      max-width: 250px;
  }
  &__icon {
    font-size: 1.2em;
    cursor: pointer;
    visibility: hidden;
  }

  &__edit {
    color: #0486fe;
    margin-left: 10px;
  }

  &__remove {
    color: #eb2929;
  }

  .el-table__row {
    &:hover {
      .event-list__icon {
        visibility: visible;
      }
    }
  }
}
</style>
