<template>
    <div class="participant-discount-info">
        {{ previousParticipantsDiscountValue }} ₽
    </div>
</template>

<script>

export default {
    name: 'ParticipantDiscountInfo',
    props: {
        /** @type {PreviousParticipantsDiscountInfoResource} */
        previousParticipantsDiscountInfo: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            isDialogOpen: false
        };
    },

    computed: {
        previousParticipantsDiscountValue() {
            return this.$prop('previousParticipantsDiscountInfo.attributes.value', 0);
        },

        /** @type {ParticipantResource[]} */
        previousParticipants() {
            return this.$prop('previousParticipantsDiscountInfo.relationships.previousParticipants', 0);
        }
    },

    methods: {
        _toggleDialog() {
            this.isDialogOpen = !this.isDialogOpen;
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.participant-discount-info {
    &__icon {
        color: $color-primary;
        cursor: pointer;
        opacity: .5;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
