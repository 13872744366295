<template>
    <el-dialog
        :visible="isVisible"
        width="600px"
        custom-class="event-add"

        :close-on-click-modal="false"
        @close="_onDialogClose"
        @open="_onDialogOpen"
    >
        <div class="event-add__title">
            Добавить фестиваль
        </div>
        <el-form
            ref="form"
            v-loading="eventsIsLoading"
            :model="form"
            :rules="rules"
            hide-required-asterisk
            label-position="top"
        >
            <el-row class="event-add__form-row">
                <el-col :span="24">
                    <el-form-item
                        label="Название"
                        prop="eventId"
                        class="event-add__form-item"
                    >
                        <el-select
                            v-model="form.eventId"
                            placeholder
                            style="width: 100%"
                        >
                            <el-option
                                v-for="event in preparedEvents"
                                :key="event.id"
                                :label="event.title"
                                :value="event.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                align="bottom"
                class="event-add__form-row"
            >
                <el-col :span="16">
                    <el-form-item
                        label="Статус"
                        prop="status"
                        class="event-add__form-item"
                    >
                        <el-select
                            v-model="form.status"
                            placeholder
                            style="width: 100%"
                        >
                            <el-option
                                v-for="status in statuses"
                                :key="status.code"
                                :label="status.title"
                                :value="status.code"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item align="right">
                        <el-button
                            type="primary"
                            @click="_onSubmit"
                        >
                            Добавить
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';

export default {
    name: 'EventHistoryAddParticipantDialog',

    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            events: [],
            eventsIsLoading: false,
            form: {
                eventId: undefined,
                status: undefined
            },
            rules: {
                eventId: [{ required: true, message: 'Укажите название фестиваля' }],
                status: [{ required: true, message: 'Укажите статус гостя' }]
            }
        };
    },

    computed: {
        ...mapState('participant', [
            'notVisitedEventList',
            'statusList'
        ]),

        preparedEvents() {
            return this.notVisitedEventList.map(item => {
                return {
                    id: item.id,
                    title: _.get(item, 'attributes.title')
                };
            });
        },

        participantId() {
            return this.$route.params.participantId;
        },

        statuses() {
            return this.statusList.map(status => {
                return {
                    code: _.get(status, 'id'),
                    title: _.get(status, 'attributes.title')
                };
            });
        }
    },
    mounted() {
        this._loadData();
    },
    methods: {
        ...mapActions('participant', [
            'getStatusList',
            'getNotVisitedEvents',
            'addParticipantToNotVisitedEvent'
        ]),
        _loadData() {
            this.eventsIsLoading = true;
            this.getStatusList();
            this.getNotVisitedEvents(this.participantId)
                .finally(() => {
                    this.eventsIsLoading = false;
                });
        },
        _onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this._tryAddParticipantToNotVisitedEvent();
                }
            });
        },
        _tryAddParticipantToNotVisitedEvent() {
            this.addParticipantToNotVisitedEvent({
                participantId: this.participantId,
                eventId: this.form.eventId,
                status: this.form.status
            }).then(response => {
                this.$emit('updated');
            });
        },
        _onDialogClose() {
            this.$refs.form.resetFields();
            this.$emit('close-dialog');
        },
        _onDialogOpen() {
            this._loadData();
        }
    }
};
</script>

<style lang="scss">
.event-add {
  .el-dialog__body {
    padding: 0 50px 60px;
  }

  &__title {
    color: #000;
    font-size: 36px;
    margin-bottom: 35px;
  }

  &__form-row {
    margin: 0;
  }

  &__form-item {
    .el-form-item__label {
      padding: 0;
      line-height: 16px;
      margin-bottom: 6px;
    }
  }
}
</style>
