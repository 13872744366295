<template>
    <div class="event-list-view">
        <h1>
            Список зарегистрированных гостей
        </h1>
        <el-button
            type="primary"
            @click="_onXlsBtnClick"
        >
            Скачать xls
        </el-button>

        <online-event-participant-list />
    </div>
</template>

<script>
import OnlineEventParticipantList from '../components/OnlineEventParticipantList';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import CONFIG_ROUTES from '@/config';
import axios from 'axios';

export default {
    name: 'EventParticipantListView',

    components: {
        OnlineEventParticipantList
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Онлайн события',
                    route: ROUTES.ONLINE_EVENT.LIST
                }
            ];
        }
    },

    methods: {
        _onXlsBtnClick() {
            const eventId = this.$route.params.onlineEventId;
            const url = `${CONFIG_ROUTES.API_HOST}/api/online-events/${eventId}/participants/download-link`;
            axios.get(url).then(response => {
                const xlsUrl = response.data;
                window.open(xlsUrl, '_blank');
            });
        }

    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
