/**
 * @param {Number} n
 * @param {Array} titles
 * @return {*}
 */
export default (n, titles) => {
    n = Math.abs(n);
    const index = (n % 100 > 4 && n % 100 < 20)
        ? 2
        : [2, 0, 1, 1, 1, 2][(n % 10 < 5) ? n % 10 : 5];
    return titles[index];
};
