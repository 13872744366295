<template>
    <div class="actions-history">
        <el-form
            :model="form"
            :rules="rules"
            label-position="left"
            @submit.native.prevent
        >
            <table class="table table--hover">
                <tr style="visibility: hidden;">
                    <td style="width: 200px;" />
                    <td />
                </tr>
                <tr v-if="_isEditMode('fio')">
                    <td colspan="2">
                        <el-form-item
                            prop="surname"
                            label="Фамилия"
                            label-width="140px"
                        >
                            <el-input
                                v-model="form.surname"
                                size="mini"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="name"
                            label="Имя"
                            label-width="140px"
                        >
                            <el-input
                                v-model="form.name"
                                size="mini"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="patronymic"
                            label="Отчество"
                            label-width="140px"
                        >
                            <el-input
                                v-model="form.patronymic"
                                size="mini"
                            />
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                size="mini"
                                type="primary"
                                @click="_saveEditingField"
                            >
                                Сохранить
                            </el-button>
                            <el-button
                                type="text"
                                size="mini"
                                @click="_cancelEditMode"
                            >
                                Отменить
                            </el-button>
                        </el-form-item>
                    </td>
                </tr>
                <tr v-else>
                    <td class="table__td table__td--gray">
                        Фамилия, имя, отчество
                    </td>
                    <td @click="_setEditMode('fio')">
                        <participant-name-with-age
                            :id="id"
                            :with-link="false"
                            :event-id="eventId"
                            :name="name"
                            :age="age"
                        />
                    </td>
                </tr>

                <tr v-if="_isEditMode('age')">
                    <td colspan="2">
                        <el-form-item
                            prop="date_of_birth"
                            label="Дата рождения"
                            label-width="140px"
                        >
                            <el-date-picker
                                v-model="form.date_of_birth"
                                :picker-options="dateOfBirthOptions"
                                :format="datepickerDateFormat"
                                value-format="dd.MM.yyyy"
                                size="mini"
                                @change="_saveEditingField"
                            />
                        </el-form-item>
                    </td>
                </tr>
                <tr
                    v-else
                    @click="_setEditMode('age')"
                >
                    <td class="table__td table__td--gray">
                        Дата рождения
                    </td>
                    <td>
                        {{ dateOfBirth }}
                    </td>
                </tr>

                <tr v-if="isChild">
                    <td class="table__td table__td--gray">
                        Участие в детской программе
                    </td>
                    <td>
                        <participant-children-program-switch
                            :participant-id="id"
                            :event-id="eventId"
                            :current-value="childrenProgram"
                        />
                    </td>
                </tr>

                <tr>
                    <td class="table__td table__td--gray">
                        Город
                    </td>
                    <td :title="city + ', ' + country">
                        {{ city }}
                    </td>
                </tr>
                <tr v-if="_isEditMode('phone')">
                    <td colspan="2">
                        <el-form-item
                            prop="phone"
                            label="Телефон"
                            label-width="140px"
                        >
                            <app-phone-input
                                v-model="form.phone"
                                size="mini"
                            />
                            <el-button
                                size="small"
                                type="primary"
                                @click="_saveEditingField"
                            >
                                Сохранить
                            </el-button>
                            <el-button
                                type="text"
                                size="mini"
                                @click="_cancelEditMode"
                            >
                                Отменить
                            </el-button>
                        </el-form-item>
                    </td>
                </tr>
                <tr
                    v-else
                    @click="_setEditMode('phone')"
                >
                    <td class="table__td table__td--gray">
                        Телефон
                    </td>
                    <td>{{ phone }}</td>
                </tr>
                <tr v-if="_isEditMode('phone2')">
                    <td colspan="2">
                        <el-form-item
                            prop="phone2"
                            label="Доп. телефон"
                            label-width="140px"
                        >
                            <app-phone-input
                                v-model="form.phone2"
                                size="mini"
                            />
                            <el-button
                                size="small"
                                type="primary"
                                @click="_saveEditingField"
                            >
                                Сохранить
                            </el-button>
                            <el-button
                                type="text"
                                size="mini"
                                @click="_cancelEditMode"
                            >
                                Отменить
                            </el-button>
                        </el-form-item>
                    </td>
                </tr>
                <tr
                    v-else
                    @click="_setEditMode('phone2')"
                >
                    <td class="table__td table__td--gray">
                        Доп. телефон
                    </td>
                    <td>{{ phone2 }}</td>
                </tr>

                <tr v-if="_isEditMode('email')">
                    <td colspan="2">
                        <el-form-item
                            prop="email"
                            label="Электронный адрес"
                            label-width="140px"
                        >
                            <el-input
                                v-model="form.email"
                                size="mini"
                            />
                            <el-button
                                size="mini"
                                type="primary"
                                @click="_saveEditingField"
                            >
                                Сохранить
                            </el-button>
                            <el-button
                                type="text"
                                size="mini"
                                @click="_cancelEditMode"
                            >
                                Отменить
                            </el-button>
                        </el-form-item>
                    </td>
                </tr>
                <tr
                    v-else
                    @click="_setEditMode('email')"
                >
                    <td class="table__td table__td--gray">
                        Электронный адрес
                    </td>
                    <td>{{ email }}</td>
                </tr>

                <tr>
                    <td class="table__td table__td--gray">
                        Заселен в
                    </td>
                    <td>
                        <template v-if="isCheckedIn">
                            <place-inline
                                v-for="place in places"
                                :key="place.id"
                                :place="place"
                            />
                            <i
                                class="el-icon-close room-checkout"
                                @click="_onCheckOutClick"
                            />
                            <check-in-btn
                                style="margin-left:10px;"
                                :selected-participant="participant"
                                re-check-in
                            />
                        </template>
                        <span v-else>
                            <check-in-btn :selected-participant="participant" />
                        </span>
                    </td>
                </tr>

                <tr v-if="_isEditMode('status')">
                    <td colspan="2">
                        <el-form-item
                            prop="status"
                            label="Статус"
                            label-width="140px"
                        >
                            <el-select
                                v-model="form.status"
                                size="mini"
                                @change="_saveEditingField"
                            >
                                <el-option
                                    v-for="status in statuses"
                                    :key="status.code"
                                    :label="status.title"
                                    :value="status.code"
                                />
                            </el-select>
                        </el-form-item>
                    </td>
                </tr>
                <tr
                    v-else
                    @click="_setEditMode('status')"
                >
                    <td class="table__td table__td--gray">
                        Статус
                    </td>
                    <td>
                        {{ participantStatus }}
                    </td>
                </tr>
                <tr>
                    <td class="table__td table__td--gray">
                        Скидка
                    </td>
                    <td>
                        <participant-discount-info
                            :previous-participants-discount-info="previousParticipantsDiscountInfo"
                        />
                    </td>
                </tr>
                <tr>
                    <td class="table__td table__td--gray">
                        Промокод
                    </td>
                    <td>
                        {{ promoCode }}
                    </td>
                </tr>
                <tr>
                    <td class="table__td table__td--gray">
                        Оплачено
                    </td>
                    <td>
                        TODO
                    </td>
                </tr>
                <tr>
                    <td class="table__td table__td--gray">
                        Допатить
                    </td>
                    <td>
                        TODO
                    </td>
                </tr>
                <tr>
                    <td class="table__td table__td--gray">
                        Трансфер
                    </td>
                    <td>
                        TODO
                    </td>
                </tr>
                <tr>
                    <td class="table__td table__td--gray">
                        Разрешить оплатить полностью
                    </td>
                    <td>
                        TODO
                    </td>
                </tr>
            </table>

            <el-row
                :gutter="16"
            >
                <el-col
                    :span="24"
                    align="right"
                >
                    <el-button
                        type="info"
                        @click="_addToTransferBtnClick"
                    >
                        Добавить в трансфер
                    </el-button>
                    <el-button
                        v-if="isAdmin"
                        type="danger"
                        icon="el-icon-delete"
                        @click="_deleteParticipantBtnClick"
                    >
                        Удалить гостя
                    </el-button>
                </el-col>
            </el-row>
            <br>
            <el-form-item label="Комментарий">
                <el-input
                    v-model="form.comment"
                    type="textarea"
                >
                    Комментарий
                </el-input>
            </el-form-item>

            <el-row :gutter="16">
                <el-col
                    :span="24"
                    align="right"
                >
                    <el-button
                        type="primary"
                        @click="_saveComment"
                    >
                        Сохранить
                    </el-button>
                </el-col>
            </el-row>
        </el-form>

        <el-card shadow="none">
            <div class="app-h2">
                Состав группы
            </div>

            <participant-short-info
                v-for="member in group"
                :key="member.id"
                :with-link="member.id !== id"
                :participant="member"
            />
        </el-card>
    </div>
</template>

<script>
import _, { get } from 'lodash';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import PlaceInline from '@/modules/hotel/components/common/PlaceInline';
import CheckInBtn from '@/modules/event/components/common/CheckInBtn';
import ParticipantShortInfo from '@/modules/participant/components/common/ParticipantShortInfo';
import ParticipantDiscountInfo from '@/modules/event/components/participant/ParticipantDiscountInfo';
import ParticipantNameWithAge from '@/modules/participant/components/common/ParticipantNameWithAge';
import ParticipantChildrenProgramSwitch from '@/modules/event/components/participant/ParticipantChildrenProgramSwitch';
import { Participant } from '@/api';
import { PARTICIPANTS } from '@/enums';
import AppPhoneInput from '@/components/form/AppPhoneInput';
import ROUTES from '@/modules/event/enums/routes';
import ROLES from '@/modules/manager/enums/roles';

/**
 * @typedef {
 * "name" | "age" | "fio" | "status" | "phone" | "email" | "phone2" | ""
 * } GeneralInfoEditMode
 */

export default {
    name: 'GeneralInfo',

    components: {
        AppPhoneInput,
        ParticipantChildrenProgramSwitch,
        ParticipantNameWithAge,
        ParticipantDiscountInfo,
        ParticipantShortInfo,
        CheckInBtn,
        PlaceInline
    },

    data() {
        return {
            form: {},
            /** @type {GeneralInfoEditMode | null} */
            editMode: null
        };
    },

    computed: {
        ...mapState('event/participants', [
            'participantCard'
        ]),

        ...mapState('config', [
            'datepickerDateFormat'
        ]),

        ...mapState('config/registration', [
            'maxAllowedAge'
        ]),

        ...mapState('auth/manager', [
            'user'
        ]),

        isAdmin() {
            return _.get(this.user, 'role') === ROLES.ADMIN;
        },

        dateOfBirthOptions() {
            const maxAllowedAge = this.maxAllowedAge;
            return {
                disabledDate(date) {
                    const diffInYears = moment()
                        .diff(date, 'years');
                    const diff = moment()
                        .diff(date);
                    return diffInYears > maxAllowedAge || diff < 0;
                }
            };
        },

        statuses() {
            // TODO: Сделать Рефакторинг
            return [
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.NEW],
                    code: PARTICIPANTS.STATUSES.NEW
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.WAIT_PREPAY],
                    code: PARTICIPANTS.STATUSES.WAIT_PREPAY
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.IN_WAIT_LIST],
                    code: PARTICIPANTS.STATUSES.IN_WAIT_LIST
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.PREPAID],
                    code: PARTICIPANTS.STATUSES.PREPAID
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.ARRIVED],
                    code: PARTICIPANTS.STATUSES.ARRIVED
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.CHECKED_IN],
                    code: PARTICIPANTS.STATUSES.CHECKED_IN
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.PAYED],
                    code: PARTICIPANTS.STATUSES.PAYED
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.CLUBS],
                    code: PARTICIPANTS.STATUSES.CLUBS
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.MOVED],
                    code: PARTICIPANTS.STATUSES.MOVED
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.REFUSED],
                    code: PARTICIPANTS.STATUSES.REFUSED
                },
                {
                    title: PARTICIPANTS.STATUSES_RU[PARTICIPANTS.STATUSES.STAFF],
                    code: PARTICIPANTS.STATUSES.STAFF
                }
            ];
        },

        isCheckedIn() {
            return Array.isArray(this.places) && this.places.length > 0;
        },

        rules() {
            return {
                name: [
                    {
                        required: true,
                        trigger: 'change',
                        message: 'Имя обязательно'
                    }
                ],

                surname: [
                    {
                        required: true,
                        trigger: 'change',
                        message: 'Фамилия обязательна'
                    }
                ]
            };
        },

        participant() {
            return this.$prop('participantCard.relationships.participant');
        },

        /** @type {PreviousParticipantsDiscountInfoResource} */
        previousParticipantsDiscountInfo() {
            return this.$prop('participantCard.relationships.discount');
        },

        id() {
            return this.$prop('participant.id');
        },

        places() {
            return this.$prop('participantCard.relationships.places');
        },

        comment() {
            return _.get(this.participant, 'attributes.comment');
        },

        name() {
            return this.$prop('participant.attributes.fullname');
        },

        age() {
            return this.$prop('participant.attributes.age');
        },

        childrenProgram() {
            return this.$prop('participant.attributes.children_program');
        },

        city() {
            return this.$prop('participant.attributes.city');
        },

        country() {
            return this.$prop('participant.attributes.country');
        },

        dateOfBirth() {
            const dateOfBirth = moment(this.$prop('participant.attributes.date_of_birth'));
            return dateOfBirth && moment.isMoment(dateOfBirth)
                ? dateOfBirth.format('DD MMMM YYYY')
                : null;
        },

        phone() {
            return this.$prop('participant.attributes.phone');
        },

        phone2() {
            return this.$prop('participant.attributes.phone2');
        },

        email() {
            return this.$prop('participant.attributes.email');
        },

        isChild() {
            return this.$prop('participant.attributes.is_child', false);
        },

        parentParticipantId() {
            return this.$prop('participant.attributes.parent_participant_id');
        },

        participantStatus() {
            return this.$prop('participant.attributes.status');
        },

        promoCode() {
            return this.$prop('participant.attributes.promo_code', 'Не применен');
        },

        /**
         * @return {ParticipantResource[]}
         */
        group() {
            return this.$prop('participantCard.relationships.group', []);
        },

        groupHasMoreThanOneMembers() {
            return this.group.length > 1;
        },

        eventId() {
            return this.$route.params.eventId;
        }
    },

    watch: {
        comment: {
            immediate: true,
            handler(value) {
                this.form.comment = value;
            }
        },

        participant() {
            this._fillForm();
        }
    },

    mounted() {
        this._fillForm();
    },

    methods: {
        ...mapActions('event/participants', [
            'participantCheckout',
            'getParticipantFullCard',
            'getTransactions',
            'updateComment'
        ]),

        ...mapActions('participant', [
            'deleteParticipant',
            'addToTransfer'
        ]),

        _addToTransferBtnClick() {
            this._addToTranfser();
        },

        _deleteParticipantBtnClick() {
            this.$confirm('Удалить гостя?', 'Внимание', {
                type: 'warning',
                confirmButtonText: 'Да',
                cancelButtonText: 'Отмена'
            })
                .then(_ => {
                    this._tryDeleteParticipant();
                })
                .catch(_ => {
                });
        },
        _addToTranfser() {
            const participantId = this.id;
            this.addToTransfer(participantId)
                .then(response => {
                    const message = _.get(response, 'message');
                    this.$message.success(message);
                })
                .catch((e) => {
                    const message = _.get(e, 'message', 'Не удалось добавить в трансфер');
                    this.$message.error(message);
                });
        },
        _tryDeleteParticipant() {
            this.deleteParticipant(this.id)
                .then(response => {
                    const message = _.get(response, 'message');
                    this.$message.success(message);
                    this.$router.push({ name: ROUTES.CARD.PARTICIPANTS });
                })
                .catch((e) => {
                    const message = _.get(e, 'message', 'Не удалось обновить статус участия в детской программе');
                    this.$message.error(message);
                });
        },

        _fillForm() {
            /** @type {ParticipantResource} */
            const p = this.participant;
            if (!p) {
                this.form = {};
                return;
            }
            this.form = {
                name: p.attributes.name,
                surname: p.attributes.surname,
                patronymic: p.attributes.patronymic,
                date_of_birth: new Date(p.attributes.date_of_birth),
                status: p.attributes.status,
                city: p.attributes.city,
                country: p.attributes.country,
                phone: p.attributes.phone,
                phone2: p.attributes.phone2,
                email: p.attributes.email,
                comment: p.attributes.comment
            };
        },

        /**
         * @param {GeneralInfoEditMode} mode
         */
        _setEditMode(mode) {
            this.editMode = mode;
        },

        /**
         * @param {GeneralInfoEditMode} mode
         * @private
         */
        _isEditMode(mode) {
            return this.editMode === mode;
        },

        _cancelEditMode() {
            this.editMode = null;
        },

        _computeUpdateParams() {
            switch (this.editMode) {
            case 'fio':
                return {
                    name: this.form.name,
                    surname: this.form.surname,
                    patronymic: this.form.patronymic
                };
            case 'status':
                return {
                    status: this.form.status
                };
            case 'phone':
                return {
                    phone: this.form.phone
                };
            case 'age':
                return {
                    date_of_birth: this.form.date_of_birth
                };
            case 'email':
                return {
                    email: this.form.email
                };
            case 'phone2':
                return {
                    phone2: this.form.phone2
                };
            default:
                return {};
            }
        },

        _refreshParticipant() {
            this.getParticipantFullCard({
                eventId: this.eventId,
                participantId: this.id
            });
        },

        _saveEditingField() {
            Participant.updateParticipantField(
                this.id,
                this._computeUpdateParams()
            )
                .then(() => {
                    this._refreshParticipant();
                    this._cancelEditMode();
                    this.$emit('update-info');
                })
                .catch(e => {
                    const message = _.get(e, 'message', 'Не удалось обновить информацию');
                    this.$message.error(message);
                });
        },

        async _saveComment() {
            let response = await this.updateComment({
                participantId: this.$route.params.participantId,
                comment: this.form.comment
            });

            if (response.success) {
                this.$message.success(response.data);
                this.$emit('update-info');
                return;
            }
            this.$message.error(response.data);
        },

        async _onCheckOutClick() {
            let response = await this.participantCheckout({
                participantId: this.id
            });
            if (response.success) {
                this.$message.success(response.data);
                await this.getParticipantFullCard({
                    eventId: this.eventId,
                    participantId: this.id
                });
                await this.getTransactions({
                    eventId: this.eventId,
                    participantId: this.id
                });
            } else {
                this.$message.error(response.data);
            }
        }
    }
};
</script>

<style lang="scss">
.room-checkout {
  margin-left: 10px;
}
</style>
