<template>
    <span class="age-text">
        {{ value }}
    </span>
</template>

<script>
import num from '@/filters/num';

export default {
    name: 'AgeText',

    props: {
        years: {
            type: Number,
            default: undefined
        },

        months: {
            type: Number,
            default: undefined
        }
    },

    computed: {
        value() {
            if (typeof this.years !== 'undefined') {
                return `${this.years} ${num(this.years, ['год', 'года', 'лет'])}`;
            }
            if (typeof this.months !== 'undefined') {
                return `${this.months} ${num(this.months, ['месяц', 'месяца', 'месяцев'])}`;
            }
            return null;
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.age-text {

}
</style>
